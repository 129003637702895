import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Blockquote, ContentBlockquote, Size, Space } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks, FigmaEmbed } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "blockquote"
    }}>{`Blockquote`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Blockquote } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=9384%3A0'} storybook={'/?path=/story/content-content--playground'} vue={'/components/HseBlockquote/HseBlockquote.html'} vueStorybook={'/?path=/story/content-content--playground'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Blockquote – это компонент для выделения цитат в потоке текста.`}</p>
    <p>{`Реализован в двух вариантах: с изображением цитируемого и без него.`}</p>
    <p>{`Вариант определяется пропом `}<inlineCode parentName="p">{`variant`}</inlineCode>{`. Возможные значения `}<inlineCode parentName="p">{`rich`}</inlineCode>{` и `}<inlineCode parentName="p">{`normal`}</inlineCode>{`. `}<inlineCode parentName="p">{`rich`}</inlineCode>{` вариант с фотографией, `}<inlineCode parentName="p">{`normal`}</inlineCode>{` без него.
Используйте вариант `}<inlineCode parentName="p">{`normal`}</inlineCode>{` для цитирования сторонних экспертов, не являющихся частью НИУ ВШЭ. Для цитирования внутренних экспертов используйте вариант с фотографией (`}<inlineCode parentName="p">{`rich`}</inlineCode>{`).`}</p>
    <p>{`Для передачи фотографии используйте проп `}<inlineCode parentName="p">{`avatarUrl`}</inlineCode>{`.`}</p>
    <p>{`На контентных страницах используйте компонент `}<inlineCode parentName="p">{`ContentBlockquote`}</inlineCode>{`. В такой версии компонента добавляются внешние вертикальные отступы.`}</p>
    <Playground __position={1} __code={'<Blockquote author=\"Толстой Л.Н.\">\n  Все люди и живут и действуют отчасти по своим мыслям, отчасти по мыслям\n  других людей. В том, насколько люди живут по своим мыслям и насколько по\n  мыслям других людей, состоит одно из главных различий людей между собою.\n</Blockquote>\n<Space vertical size={Size.large_x} />\n<Blockquote\n  variant={Blockquote.Variant.rich}\n  avatarUrl=\"https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png\"\n  author=\"Иванов Иван Иванович\"\n>\n  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab adipisci\n  asperiores consequuntur dicta, dolor eaque, error esse facilis fugiat\n  mollitia, quasi quisquam sunt? A dignissimos eaque explicabo harum\n  repellendus, totam.\n</Blockquote>'} __scope={{
      props,
      DefaultLayout,
      Blockquote,
      ContentBlockquote,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Blockquote author="Толстой Л.Н." mdxType="Blockquote">
    Все люди и живут и действуют отчасти по своим мыслям, отчасти по мыслям
    других людей. В том, насколько люди живут по своим мыслям и насколько по
    мыслям других людей, состоит одно из главных различий людей между собою.
  </Blockquote>
  <Space vertical size={Size.large_x} mdxType="Space" />
  <Blockquote variant={Blockquote.Variant.rich} avatarUrl="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png" author="Иванов Иван Иванович" mdxType="Blockquote">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab adipisci
    asperiores consequuntur dicta, dolor eaque, error esse facilis fugiat
    mollitia, quasi quisquam sunt? A dignissimos eaque explicabo harum
    repellendus, totam.
  </Blockquote>
    </Playground>
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node="?node-id=9470:251" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node="?node-id=9470:363" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "варианты-отображения"
    }}>{`Варианты отображения`}</h3>
    <p>{`Есть два варианта отображения: `}<inlineCode parentName="p">{`normal`}</inlineCode>{` и `}<inlineCode parentName="p">{`rich`}</inlineCode>{`.`}</p>
    <Playground __position={4} __code={'<Blockquote author=\"Толстой Л.Н.\">\n  Все люди и живут и действуют отчасти по своим мыслям, отчасти по мыслям\n  других людей. В том, насколько люди живут по своим мыслям и насколько по\n  мыслям других людей, состоит одно из главных различий людей между собою.\n</Blockquote>\n<Space vertical size={Size.large_x} />\n<Blockquote\n  variant={Blockquote.Variant.rich}\n  avatarUrl=\"https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png\"\n  author=\"Иванов Иван Иванович\"\n>\n  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab adipisci\n  asperiores consequuntur dicta, dolor eaque, error esse facilis fugiat\n  mollitia, quasi quisquam sunt? A dignissimos eaque explicabo harum\n  repellendus, totam.\n</Blockquote>'} __scope={{
      props,
      DefaultLayout,
      Blockquote,
      ContentBlockquote,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Blockquote author="Толстой Л.Н." mdxType="Blockquote">
    Все люди и живут и действуют отчасти по своим мыслям, отчасти по мыслям
    других людей. В том, насколько люди живут по своим мыслям и насколько по
    мыслям других людей, состоит одно из главных различий людей между собою.
  </Blockquote>
  <Space vertical size={Size.large_x} mdxType="Space" />
  <Blockquote variant={Blockquote.Variant.rich} avatarUrl="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png" author="Иванов Иван Иванович" mdxType="Blockquote">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab adipisci
    asperiores consequuntur dicta, dolor eaque, error esse facilis fugiat
    mollitia, quasi quisquam sunt? A dignissimos eaque explicabo harum
    repellendus, totam.
  </Blockquote>
    </Playground>
    <h3 {...{
      "id": "использование-на-контентных-страницах"
    }}>{`Использование на контентных страницах`}</h3>
    <p>{`Для контентных страниц используйте компонент `}<inlineCode parentName="p">{`ContentBlockquote`}</inlineCode>{`.`}</p>
    <p>{`В такой версии компонента добавляются внешние вертикальные отступы размером в `}<inlineCode parentName="p">{`64px`}</inlineCode></p>
    <Playground __position={5} __code={'<ContentBlockquote author=\"Толстой Л.Н.\">\n  Все люди и живут и действуют отчасти по своим мыслям, отчасти по мыслям\n  других людей. В том, насколько люди живут по своим мыслям и насколько по\n  мыслям других людей, состоит одно из главных различий людей между собою.\n</ContentBlockquote>\n<ContentBlockquote\n  variant={Blockquote.Variant.rich}\n  avatarUrl=\"https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png\"\n  author=\"Иванов Иван Иванович\"\n>\n  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab adipisci\n  asperiores consequuntur dicta, dolor eaque, error esse facilis fugiat\n  mollitia, quasi quisquam sunt? A dignissimos eaque explicabo harum\n  repellendus, totam.\n</ContentBlockquote>'} __scope={{
      props,
      DefaultLayout,
      Blockquote,
      ContentBlockquote,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ContentBlockquote author="Толстой Л.Н." mdxType="ContentBlockquote">
    Все люди и живут и действуют отчасти по своим мыслям, отчасти по мыслям
    других людей. В том, насколько люди живут по своим мыслям и насколько по
    мыслям других людей, состоит одно из главных различий людей между собою.
  </ContentBlockquote>
  <ContentBlockquote variant={Blockquote.Variant.rich} avatarUrl="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png" author="Иванов Иван Иванович" mdxType="ContentBlockquote">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab adipisci
    asperiores consequuntur dicta, dolor eaque, error esse facilis fugiat
    mollitia, quasi quisquam sunt? A dignissimos eaque explicabo harum
    repellendus, totam.
  </ContentBlockquote>
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Blockquote} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на корневой элемент `}<inlineCode parentName="p">{`blockquote`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      